

let academy = ""

export function saveAcademy(type: string) {
    academy = type;
}

export function loadAcademy() {
    return academy;
}

let isMaleS = JSON.parse(sessionStorage.getItem("isMale") || "false");
export function selectUserType(isMale: boolean) {
    isMaleS = isMale
    sessionStorage.setItem("isMale", isMale ? "true" : "false");
}

export function getUserType() {
    return isMaleS;
}


const openId = sessionStorage.getItem("openId");
export function getOpenId() {
    return openId;
}

const userName = sessionStorage.getItem("nickname") || "";

export function getUserName() {
    return userName;
}

export async function loadImg(source: Promise<typeof import("*.png")>, img: HTMLImageElement = new Image()) {
    return new Promise<HTMLImageElement>(async (resolve) => {
        img.onload = () => {
            resolve(img);
        }

        img.src = (await source).default;
    })
}

const getItem = new Set<number>();

export function saveItem(ids: number[]) {
    for (let id of ids) {
        getItem.add(id);
    }
}

export function loadItem() {
    return getItem;
}

export var introduce = [
    {
        title: '水城门',
        item: '白鹭',
        descrption: '位于南城墙附近的水城门，重现了当时良渚古城河道上舟楫穿梭的景象。',
        question: '5000年前的良渚古城水系发达，鸟类丰富，地理上属于哪个区域？',
        answer: ['A、长江中游地区', 'B、长江下游地区'],
        success: 'B'
    },
    {
        title: '南城墙站',
        item: '狗尾草',
        descrption: '南城墙遗址，是良渚古城遗址公园内为数不多的真实裸露展示剖面的点。',
        question: '良渚古城内城城墙发现并确认于2007年，内城城墙充分利用自然地势堆筑而成，全长约为多少公里？',
        answer: ['A、6公里', 'B、10公里'],
        success: 'A'
    },
    {
        title: '钟家港居住与作坊区',
        item: '菱角',
        descrption: '在河道与作坊区，可以看到贯通良渚古城南北的主干道——钟家港古河道。',
        question: '专家证实5000年前的良渚先民已经在食用菱角，这种说法正确吗？',
        answer: ['A、正确', 'B、错误'],
        success: 'A'
    },
    {
        title: '湿地营城',
        item: '陶屋顶',
        descrption: '“湿地营城”“水城中的劳作与生活”展厅，展示了良渚古城的地理环境、良渚先民的生活场景。',
        question: '良渚文化时期文物“陶屋顶”，出土于哪个遗址？',
        answer: ['A、瑶山遗址', 'B、卞家山遗址'],
        success: 'B'
    },
    {
        title: '鹿苑',
        item: '小鹿',
        descrption: '鹿苑是小鹿们的家，五千年前的良渚气候温暖湿润，非常适合鹿科动物生存。',
        question: '良渚遗址曾发现麋鹿、水鹿、梅花鹿等5种鹿科动物遗存，请问良渚古城遗址公园鹿苑的鹿属于哪一种？',
        answer: ['A、麋鹿', 'B、梅花鹿'],
        success: 'B'
    },
    {
        title: '池中寺粮仓',
        item: ' 黑陶罐',
        descrption: '池中寺粮仓，专家曾在此发现约40万斤碳化稻谷，堪称“皇家粮仓”。',
        question: '良渚先民使用黑陶罐主要是做什么的？',
        answer: ['A、盛放、储存粮食、水等', 'B、烹煮食物'],
        success: 'A'
    },
    {
        title: '大莫角山',
        item: '鸟立高台玉璧',
        descrption: '大莫角山视野开阔，是莫角山台地上3个独立土台中规模最大的一个。',
        question: '良渚先民雕刻玉器主要使用的工具是什么？',
        answer: ['A、刻刀', 'B、燧石'],
        success: 'B'
    },
    {
        title: '小莫角山',
        item: '鸽子',
        descrption: '小莫角山台地是莫角山台地上规模最小的一处，放置了依据柱洞排列模拟复原的建筑立体模型。',
        question: '小莫角山房屋基址原为良渚文化时期__的宗教和礼仪活动场所？',
        answer: ['A、贵族阶级 ', 'B、平民阶级'],
        success: 'A'
    },
    {
        title: '雉山',
        item: '水稻',
        descrption: '雉山海拔30余米，登上雉山观景台，可以俯瞰整个良渚古城。',
        question: '5000年前的良渚古城，人们以什么为主食？',
        answer: ['A、大米 ', 'B、黍'],
        success: 'A'
    },
    {
        title: '反山王陵',
        item: '玉琮',
        descrption: '反山王陵是最高等级的良渚王的墓葬区，与良渚王的居住地莫角山宫殿仅一河之隔。',
        question: '良渚玉琮中最重、最大、做工最精美的一件,被誉为“玉琮王”，它的重量是多少？',
        answer: ['A、4.5公斤', ' B、6.5公斤'],
        success: 'B'
    },
    {
        title: '老虎岭坝体',
        item: ' 芦苇',
        descrption: '老虎岭水坝所属的谷口高坝系统，由岗公岭、老虎岭、周家畈以及秋坞、石坞和蜜蜂垄六条坝体组成。',
        question: '良渚文化时期的水利工程中，常使用淤泥、芦荻等做成“草裹泥”，主要起什么作用？',
        answer: ['A、增加坝体的抗拉强度', 'B、方便移动坝体 '],
        success: 'A'
    },
    {
        title: '瑶山祭坛',
        item: '龙首纹玉镯',
        descrption: '瑶山遗址公园内的瑶山祭坛，是先于瑶山墓地和城址建造的重要祭祀遗址。',
        question: '良渚文化时期的玉镯，什么性别的人可以佩戴？',
        answer: ['A、女性 ', 'B、男性和女性'],
        success: 'B'
    }
];