import { useState, useEffect, useRef } from 'react';
import { useSpring, animated, config } from 'react-spring'
import Home from "./pages/home/home";
import Selectuser from "./pages/selectUser/index";
import Map from "./pages/map/index";
import Card from "./pages/card/index";
import './App.css';

const bg = require("./images/bg.mp3");

async function wxLink(appId: string) {
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent("https://westlake.q-huan.link/api/wxlogin")}&response_type=code&scope=snsapi_base&state=westlake#wechat_redirect`;
  window.location.href = url;
}

const bglist = [
  {
    bgColor: "#E7F0F9",
    el: Home
  },
  {
    bgColor: "#FDEFE2",
    el: Selectuser
  },
  {
    bgColor: "#FFF",
    el: Map
  },
  {
    bgColor: "#E7F0F9",
    el: Card
  }
];

function App() {
  useEffect(() => {
    const handler = (e: any) => {
      console.log("handler");
      e.preventDefault()
    }
    document.addEventListener('gesturestart', handler)
    document.addEventListener('gesturechange', handler)
    document.addEventListener('gestureend', handler)
    return () => {
      document.removeEventListener('gesturestart', handler)
      document.removeEventListener('gesturechange', handler)
      document.removeEventListener('gestureend', handler)
    }
  }, [])

  const [index, changeIndex] = useState(0);

  const audioRef = useRef<HTMLAudioElement>(null);

  const Page = bglist[index];

  const { bgColor } = useSpring({ bgColor: Page.bgColor, immediate: true });

  useEffect(() => {
    if (audioRef.current)
      audioRef.current.oncanplay = function () {
        console.log("can play");
        // play();
      }
  }, [])

  return <div onPointerDown={play}>
    <animated.div className="bg" style={{ backgroundColor: bgColor }} />
    <Page.el next={next} prew={prew} />
    {<audio src={bg} loop ref={audioRef} />}
  </div>

  async function next(animated: any) {
    changeIndex(index + 1);
  }

  async function prew(animated: any) {
    changeIndex(index - 1);
  }

  function play() {
    if (audioRef.current && audioRef.current.paused) {
      audioRef.current.play();
    }
  }
}

export default App;

