import styles from './styles.module.css'
import { useSpring, animated } from 'react-spring'
import { useNextGesture } from '../../utils/useNextGesture';
import { useEffect, useState } from 'react';

export default function Home({ next }: any) {
    const base = -200;

    const [delta, changeDelta] = useState(base);

    const [isEnd, domTarget] = useNextGesture(changeDelta);

    useEffect(() => {
        changeDelta(isEnd ? base : 0);
    }, [isEnd]);

    const { y, opacity } = useSpring({
        y: delta,
        opacity: isEnd ? 0 : 1,
        config: { mass: 2, tension: 350, friction: 40 },
        onRest: () => isEnd && next()
    })

    const y1 = y.to(p => -p);

    return <div className={styles.page} ref={domTarget}>
        <animated.div className={styles.home} style={{ x: y, opacity }} />
        <animated.div className={styles.xiahua} style={{ y: y, opacity }} >
        </animated.div>
    </div>
}