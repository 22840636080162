import { useGesture } from '@use-gesture/react';
import React, { useEffect, useRef, useState } from 'react';

export function useNextGesture(changeEvent: (input: number, isEnd: boolean) => void, canDrag: boolean = true): [boolean, React.MutableRefObject<HTMLDivElement | null>] {
    const domTarget = useRef<HTMLDivElement>(null);

    const [isEnd, changeEnd] = useState(false);

    useGesture(
        {
            onDrag: ({ down, movement: [mx, my], velocity: [_, velocity] }) => {
                if (canDrag && my <= 0) {
                    const change = velocity > 0.2;

                    if (!down && change) {
                        changeEnd(change);
                        changeEvent(0, change)
                    } else {
                        changeEvent(0, isEnd);
                    }

                    if (down && my <= 0)
                        changeEvent(my, isEnd);
                }
            }
        },
        { target: domTarget, eventOptions: { passive: false } }
    );

    return [isEnd, domTarget];
}
