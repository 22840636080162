import styles from './styles.module.css'
import { useSpring, useSprings, animated } from 'react-spring'
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import { getUserType, introduce, loadImg, saveItem } from '../../utils/store';

const maxWidth = 2000;
const maxHeight = 1338;

const pointerLength = [
    71.58910369873047,
    368.9104919433594,
    617.3053588867188,
    807.3711547851562,
    977.6650390625,
    1216.2022705078125,
    1452.210693359375,
    1708.335693359375,
    1849.757080078125,
    2373.6845703125,
    3068.306640625,
    4604.177734375
]

const maxLength = pointerLength[pointerLength.length - 1];

const startPoint = [1200, 1100];
// "M1230 1035",
const pointers = [
    "L1230 1035",
    "L1450 835",
    "L1290 645",
    "L1180 800",
    "L1050 910",
    "L850 780",
    "L1040 640",
    "L1200 440",
    "L1300 340",
    "L820 550",
    "L130 470",
    "L1630 140"
]
const stopPointIndexs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const bgImg = import("../../images/map/map_1.png");
const wordImg = import("../../images/map/map_2.png");


function Question({ display, checkQ, nextG }: any) {
    return <div className={styles.bg}>
        <div className={styles.academyInfo}>
            <div className={styles.img} style={{ backgroundImage: `url(/assets/introduce/introduce_${display.index}.png)` }}></div>
            <div className={styles.descrption}>
                <div className={styles.title}>{display.index}. {display.question}</div>
                <div className={styles.answer}>
                    {display.answer.map((val: any, index: number) => <div key={val} {...checkQ(index)}>
                        {val}
                    </div>)}
                </div>
                <div className={styles.jump} {...nextG()}>
                    跳过
                </div>
            </div>
            <div className={styles.closebtn}  {...nextG()}>
            </div>
        </div>
    </div>
}

function QuestionSuccess({ display, nextG }: any) {
    return <div className={styles.bg}>
        <div className={styles.answerstatus}>
            <div className={styles.answersuccess}>
                <div className={styles.img} style={{ backgroundImage: `url(/assets/items/${display.index}.png)` }}></div>
                <div>
                    获得风物: {display.item}
                </div>
            </div>
            <div className={styles.nextstatus}>
                <div className={styles.successbtn} {...nextG()}>
                </div>
                <div>
                    {display.isEnd ? "你已经完成寻宝，快去领取考古勋章吧！" : "请继续前往下一站寻宝吧"}
                </div>
            </div>
        </div>
    </div>
}

function QuestionFail({ display, nextG }: any) {
    return <div className={styles.bg}>
        <div className={styles.answerstatus}>
            <div className={styles.answerfail}>
                <div>
                    本站风物: {display.item}
                </div>
                <div className={styles.failMessage}>
                    你完美避开了正确答案
                </div>
                <div>
                    正确答案
                </div>
                <div className={styles.answerTxt}>
                    <div>
                        {display.success}
                    </div>
                    {display.answer[display.success.charCodeAt(0) - 65].replace(/^[A|B]、/, "")}
                </div>

            </div>
            <div className={styles.nextstatus}>
                <div className={styles.failbtn}  {...nextG()}>
                </div>
                <div>
                    {display.isEnd ? "你已经完成寻宝，快去领取考古勋章吧！" : "请继续前往下一站寻宝吧"}
                </div>
            </div>
        </div>
    </div>
}

export default function Index({ next }: any) {
    const domTarget = useRef<any>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const origin = useRef<{ x: number, y: number, stop: boolean, isStart: boolean, start?: () => void }>({ x: 0, y: 0, stop: false, isStart: false })
    const info = useRef<any>(null);
    const [display, displayInfo] = useState<any>(undefined);
    const [isFirst, changeFirst] = useState<any>(true);
    const [button, showButton] = useState(false);

    const isMale = getUserType();

    const [{ x: mapX, y: mapY, ox, oy, scale, scaleX, opacity }, api] = useSpring(() => ({ x: 0, y: 0, ox: 0, oy: 0, scale: 1, scaleX: 1, rotateZ: 0, opacity: 0 }))

    const definePath = useRef<SVGPathElement>(null);
    const pathEL = useRef<SVGPathElement>(null);
    const backgroundRef = useRef<HTMLImageElement>(null);
    const wordRef = useRef<HTMLImageElement>(null);

    useGesture({
        onDrag: ({ down, pinching, cancel, movement: [mx, my], offset: [ox, oy], velocity: [_, velocity] }) => {
            if (origin.current.start) {
                const { x, y, start } = origin.current;

                origin.current.stop = down;
                api.start({ x: down ? x + mx : x, y: down ? y + my : y, immediate: down })

                if (!down && !display && start) {
                    start();
                }
            } else {
                if (pinching) return cancel()
                api.start({ x: ox, y: oy })
            }
        },
        onPinch: ({ origin: [ox, oy], first, movement: [ms], offset: [s, a], memo }) => {
            if (origin.current.start) return;

            if (first && mapRef.current) {
                const { width, height, x, y } = mapRef.current.getBoundingClientRect()
                const tx = ox - (x + width / 2)
                const ty = oy - (y + height / 2)
                memo = [mapX.get(), mapY.get(), tx, ty]
                changeFirst(false);

                setTimeout(() => {
                    if (!origin.current.start) showButton(true)
                }, 1000)
            }

            const x = memo[0] - (ms - 1) * memo[2]
            const y = memo[1] - (ms - 1) * memo[3]
            api.start({ scale: s, rotateZ: a, x, y })
            return memo
        }
    }, {
        target: domTarget,
        drag: { from: () => [mapX.get(), mapY.get()] },
        pinch: { scaleBounds: { min: 0.1875, max: 1.5 }, rubberband: true },
    })

    const bind = useGesture({
        onClick() {
            showButton(false);
            startAnimation();
        }
    });

    const checkQ = useGesture({
        onClick({ args }) {
            const value = args[0];

            if (display) {
                const isSuccess = (display.success.charCodeAt(0) - 65) == value;

                display.getItem = isSuccess;
                display.toAnswer = true;
                display.isEnd = display.index == introduce.length;

                console.log(display);
                displayInfo({ ...display });
            }
        }
    });

    const nextG = useGesture({
        onClick() {
            if (display && display.index == introduce.length) {
                saveItem(info.current.filter((p: any) => p.getItem).map((p: any) => p.index));
                next();
            } else if (origin.current.start) {
                origin.current.start();
            }
        }
    });

    useLayoutEffect(() => {
        if (origin.current.isStart) return;
        origin.current.isStart = true;

        info.current = introduce.map((item, i) => ({ index: i + 1, ...item, getItem: false, toAnswer: false }));

        let { offsetWidth, offsetHeight } = domTarget.current;

        if (backgroundRef.current && wordRef.current) {
            let scale = offsetWidth / maxWidth;
            let startX = - (maxWidth - offsetWidth) / 2;
            let startY = - (maxHeight - offsetHeight) / 2;

            api.start({ x: startX, y: startY, scale, immediate: true });

            Promise.all([loadImg(bgImg, backgroundRef.current),
            loadImg(wordImg, wordRef.current),
            loadImg(import("../../images/map/button_2.png"), new Image())]).then(async () => {
                api.start({ x: startX, y: startY, scale, immediate: true });
            });
        }
    }, [])

    type Args1<T> = T extends (args1: infer T1, ...args: any[]) => any ? T1 : never;

    function nextAction(props: Args1<typeof api.start>, duration: number) {
        return new Promise((resolve) => {
            api.start({
                ...props, config: { duration },
                onRest: resolve
            });
        })
    }

    return (
        <div className={styles.page} ref={domTarget}>
            {display && (!display.toAnswer ? <Question display={display} checkQ={checkQ} nextG={nextG} /> : (
                display.getItem ? <QuestionSuccess display={display} nextG={nextG} /> : <QuestionFail display={display} nextG={nextG} />
            ))}
            {isFirst && <div className={styles.iwenzi} />}
            <animated.div className={styles.map} style={{ x: mapX, y: mapY, scale }} ref={mapRef}>
                <animated.img className={styles.image} ref={backgroundRef} />
                <svg style={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%" }} viewBox={`0 0 ${maxWidth} ${maxHeight}`}>
                    <path ref={definePath} d="M1200 1100 L1230 1035, L1450 835, L1290 645, L1180 800, L1050 910, L850 780, L1040 640, L1200 440, L1300 340, L820 550, L130 470, L1630 140" fill='none' stroke='none' />
                    <path ref={pathEL} fill='none' stroke="#D64733" strokeDasharray="10 8" strokeWidth="6" />
                </svg>
                <animated.div className={`${styles.moveu} ${!isMale ? styles.king : ""}`} style={{ x: ox, y: oy, scaleX, opacity }}>
                </animated.div>
                <img className={styles.image} ref={wordRef} />
            </animated.div>
            {button && <animated.div className={styles.button} {...bind()}></animated.div>}
        </div>
    );

    function startAnimation() {
        let centerX = domTarget.current.offsetWidth / 2, centerY = domTarget.current.offsetHeight - 300;

        let step = 0;
        let stoped = 0;
        let stopPointIndex = stopPointIndexs[0];
        let index = 0;
        let prewDate = +new Date();
        let maxTime = 30 * 1000;
        let exTime = 0;
        let prewX = 0;
        let prewUp = 1;
        let ps = 1;

        move(startPoint[0], startPoint[1]);

        origin.current.start = () => {
            prewDate = +new Date();
            origin.current.stop = false;
            displayInfo(undefined);
            startAnimation();
        };

        startAnimation();

        function nextStop() {
            origin.current.stop = true;
            stopPointIndex = stopPointIndexs[stoped]

            const dinfo = info.current[index];

            index++

            displayInfo(dinfo);
        }

        function move(x: number, y: number, duration?: number) {
            const ox = (centerX - x * ps);
            const oy = (centerY - y * ps);

            origin.current.x = ox;
            origin.current.y = oy;

            if (prewX != ox)
                prewUp = prewX > ox ? 1 : -1;

            api.start({ x: ox, y: oy, scale: 1, ox: x * ps, oy: y * ps, scaleX: prewUp, opacity: 1, immediate: (key) => key == "ox" || key == "oy" || key == "scaleX" || key == "opacity", config: { duration } });
            prewX = ox;
        }

        function startAnimation() {
            requestAnimationFrame((id) => {
                if (origin.current.stop) {
                    return;
                }

                let now = (+new Date());
                let nowTime = exTime + now - prewDate;
                let pos = nowTime / maxTime;

                prewDate = now;
                exTime = nowTime;

                if (nowTime / maxTime >= 1) {
                    cancelAnimationFrame(id);
                    nextStop();
                    return;
                }

                const current = maxLength * (pos);

                const stepLength = pointerLength[step];

                if (current > stepLength) {
                    step++;

                    if (step == stopPointIndex) {
                        stoped++;
                        cancelAnimationFrame(id);
                        nextStop();
                        return;
                    }
                }

                const { x, y } = definePath.current?.getPointAtLength(current) || { x: 0, y: 0 };

                let path = ["M" + startPoint.join(" "), ...pointers.slice(0, step), `L${x} ${y}`].join(",");
                pathEL.current?.setAttribute("d", path);

                move(x, y)
                startAnimation();
            })
        }
    }
}