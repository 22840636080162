import styles from './styles.module.css'
import { useSpring, useSprings, animated } from 'react-spring'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import { getUserName, getUserType, introduce, loadAcademy, loadItem } from '../../utils/store';

export type Format = {
    text: string;
    fontFamily: string
    fontSize: number
    font: string;
    lineHeight: number;
    color: string
    underline?: boolean;
    italic?: boolean;
    bold?: boolean;
};

function buildTable(datas: Format[][][], measureTextWith: (text: Format) => number) {
    let rows = datas.map(_ => 0);
    let cols = datas[0].map(_ => 0);

    for (var j = 0; j < datas.length; j++) {
        let tr = datas[j];
        let maxHeight = 0;

        for (var i = 0; i < tr.length; i++) {
            let item = tr[i];
            let maxWidth = cols[i];
            let height = 0;

            for (var line of item) {
                let textWidth = measureTextWith(line);

                if (maxWidth < textWidth)
                    maxWidth = textWidth;

                height += line.lineHeight;
            }


            if (maxHeight < height) {
                maxHeight = height;
            }

            cols[i] = maxWidth;
        }

        rows[j] = maxHeight
    }

    return {
        rows,
        cols
    }
}

function T(text: string, color: string, fontSize: number, fontFamily = "UseFont"): Format {
    return {
        text,
        fontSize,
        color,
        lineHeight: fontSize * 1.6,
        fontFamily: fontFamily,
        font: `${fontSize}px ${fontFamily}`,
    }
}

const LT = (text: string, color: string, fontFamily = "UseFont") => T(text, color, 32, fontFamily);

function drawText(ctx: CanvasRenderingContext2D, text: Format, x: number, y: number) {
    ctx.font = text.font;
    ctx.fillStyle = text.color;
    ctx.textBaseline = "top";
    ctx.textAlign = "center";
    ctx.fillText(text.text, x, y);
}

function createDrawLine(ctx: CanvasRenderingContext2D, startX: number, startY: number) {
    let y = startY;

    return {
        drawLine(text: Format, marginTop = 0) {
            y += marginTop;
            drawText(ctx, text, startX, y);

            y += text.lineHeight;
        }
    }
}

interface DrawInfo {
    width: number;
    height: number;
    isMale: boolean;
    value: number;
    items: Set<number>;
}

interface Position {
    left?: number;
    top?: number;
    right?: number;
    bottom?: number;

    widthScale?: number;
    heightScale?: number;
}

async function drawCover(canvas: HTMLCanvasElement, info: DrawInfo) {
    let ctx = canvas.getContext("2d");
    if (ctx) {
        let bgImgP: Promise<typeof import("*.png")>
        let position: Position;
        let color: string;
        let scale = 1;


        if (info.value > 10) {
            bgImgP = import("../../images/shared/fenxiang_3.png");
            color = "#189099";
        } else if (info.value > 6) {
            bgImgP = import("../../images/shared/fenxiang_2.png");
            color = "#9F3F60";
        } else {
            bgImgP = import("../../images/shared/fenxiang_1.png");
            color = "#CE8E3E";
        }


        let [
            _font,
            { default: bg },
            // { default: card },
            { default: logo },
            { default: user }] = await Promise.all([
                document.fonts.load("12px UseFont"),
                bgImgP,
                import("../../images/shared/qr-code.png"),
                info.isMale ? import("../../images/shared/queen_2.png") : import("../../images/shared/king_1.png")
            ]);

        await drawImages(ctx, bg, info, { left: 0, bottom: 0, widthScale: scale, heightScale: scale });

        // await drawImages(ctx, card, info, { right: 9, top: 300, widthScale: scale, heightScale: scale });

        await drawImages(ctx, user, info, { right: 10, bottom: 5, widthScale: 0.65, heightScale: 0.65 });

        await drawImages(ctx, logo, info, { left: 115, bottom: 80, widthScale: 0.35, heightScale: 0.35 });


        var sy = 630,
            sx = 190,
            builder = createDrawLine(ctx, sx, sy);

        ctx.fillStyle = color;

        console.log(ctx.fillStyle);

        builder.drawLine(LT(info.value + "", color), 30)

        builder.drawLine(LT("长按保存图片", color), 290)
    }
}

function drawImages(ctx: CanvasRenderingContext2D, src: string, info: DrawInfo, position: Position) {
    return new Promise(function (resolve, reject) {
        let image = new Image();

        image.onload = function () {
            let x = position.left,
                y = position.top;

            let width = image.width * (position.widthScale || 1),
                height = image.height * (position.heightScale || 1);

            if (position.top === undefined && position.bottom !== undefined) {
                y = info.height - height - position.bottom;
            }
            if (position.left === undefined && position.right !== undefined) {
                x = info.width - width - position.right;
            }

            ctx.drawImage(image, x || 0, y || 0, width, height);

            resolve(image);
        }

        image.onerror = reject;

        image.src = src;
    })

}

function GetItems({ close }: any) {
    const [itemIndex, showItem] = useState(-1);
    const items = loadItem();

    const bind = useGesture({
        onClick() {
            close();
        }
    })

    const selectedItem = useGesture({
        onClick({ event, args }) {
            const index = args[0];
            showItem(index);

            event.stopPropagation();
        }
    })

    return <div className={styles.page} {...bind()}>
        <div className={styles.itembg}>
            {introduce.map((p, index) => <div className={styles.item} key={p.item} {...selectedItem(index + 1)}>
                {items.has(index + 1) ?
                    <div className={styles.itemImg} style={{ backgroundImage: `url(/assets/items/${index + 1}.png)` }}  ></div> :
                    <div className={styles.itemImg} style={{ backgroundImage: `url(/assets/items-l/${index + 1}_1.png)` }} ></div>
                }
                {p.item}
            </div>)}
            <div className={styles.closebtn2} {...bind()}>
                关闭
            </div>
        </div>
        {itemIndex >= 0 && <ItemShow defaultIndex={itemIndex} close={() => showItem(-1)} />}

    </div>
}


function ItemShow({ close, defaultIndex = 1 }: any) {
    const items = loadItem();

    const domTarget = useRef<HTMLDivElement>(null);
    const region = useRef({ width: 0, current: 0, left: 1, right: 2, other: 3, beforeOther: 3, index: defaultIndex, moveX: -1 });
    const [currentIndex, changeIndex] = useState(defaultIndex);

    function resetIndex(index: number) {
        if (index < 1) {
            return introduce.length;
        }

        if (index > introduce.length) {
            return 1;
        }

        return index;
    }

    useGesture(
        {
            onDrag: ({ event, down, movement: [mx, my], velocity: [vx] }) => {
                const changeCard = vx > 0.2;

                region.current.moveX = Math.abs(mx) > Math.abs(my) ? 1 : 0;

                if (down) {
                    if (region.current.moveX === 1) {
                        api.start(i => reset(i, mx));
                    }
                } else {
                    if (region.current.moveX === 1 && changeCard) {
                        const now = region.current;
                        const { current, left, right, other } = now;

                        now.beforeOther = other;

                        if (mx > 0) {
                            region.current.index -= 1;
                            now.current = right;
                            now.left = current;
                            now.right = other;
                            now.other = left;
                        } else {
                            region.current.index += 1;
                            now.current = left;
                            now.left = other;
                            now.right = current;
                            now.other = right;
                        }

                        region.current.index = resetIndex(region.current.index)

                        changeIndex(region.current.index);

                        api.start(i => reset(i, 0));
                        return;
                    }

                    region.current.moveX = -1;

                    api.start(i => reset(i));
                }
            }
        },
        { target: domTarget, eventOptions: { passive: false } }
    );

    const closeBind = useGesture({
        onClick({ event }) {
            close();
            event.stopPropagation();
        }
    })

    const ctnBind = useGesture({
        onClick({ event }) {
            event.stopPropagation();
        }
    })

    const [props, api] = useSprings(4, i => ({
        from: { opacity: 0, x: 0, y: 0, scale: 0.8, zIndex: 1, index: 1, rotate: 0 },
        config: { mass: 2, tension: 250, friction: 40 }
    }))

    useLayoutEffect(() => {
        region.current.width = (domTarget.current?.offsetWidth || 800);

        api.start(i => reset(i, 0, true));
    }, []);

    function reset(i: number, mx = 0, isStart = false) {
        const { width: maxWidth, current, left, right, index, beforeOther } = region.current;

        const immediate = (key: string) => key == "zIndex" || key == "index" || (isStart && key == "x")

        const immediateNext = (key: string) => key == "zIndex" || key == "index" || key == "x" || key == "opacity"

        if (i == current) {
            return { x: mx, y: 0, scale: 1, opacity: 1, rotate: 0, zIndex: 3, index, immediate };
        }

        if (i == left) {
            const next = resetIndex(index + 1);

            return { x: mx + maxWidth, y: 0, scale: 1, opacity: 1, zIndex: 2, index: next, immediate: beforeOther == i ? immediateNext : immediate };
        }

        if (i == right) {
            const prew = resetIndex(index - 1);

            return { x: mx - maxWidth, y: 0, scale: 1, opacity: 1, zIndex: 2, index: prew, immediate: beforeOther == i ? immediateNext : immediate };
        }

        return { scale: 1, x: 0, y: 0, opacity: 0, zIndex: 1, index, immediate: immediateNext };
    }

    return (
        <div className={styles.page} {...ctnBind()}>
            <div className={styles.bg} ref={domTarget}>
                {props.map(({ x, y, scale, opacity, rotate, index, zIndex }, i) => (
                    <animated.div key={i} className={`${styles.itemShow}`} style={{
                        x, y, rotate, transform: scale.to(p => `scale(${p})`), opacity, zIndex, color: index.to(i => items.has(i) ? "#97621D" : "#3D3D3D"), backgroundImage: index.to(p => items.has(p) ? `url(/assets/bar.png), url(/assets/items/${p}\\.png)` : `url(/assets/bar.svg), url(/assets/items-l/${p}_1\\.png)`)
                    }}>{index.to(i => introduce[i - 1].item)}</animated.div>
                ))}
                <div className={styles.cardIndex}>
                    {introduce.map((p, index) => <div key={p.item} className={currentIndex == index + 1 ? styles.selected : ""}></div>)}
                </div>
            </div>
            <div className={styles.closebtn} {...closeBind()}>
            </div>
        </div>
    );
}

export default function Index({ next, prew }: any) {
    const cavansRef = useRef<{ canvas: HTMLCanvasElement | null }>({ canvas: null })
    const imageRef = useRef<HTMLImageElement>(null)

    const [isDialog, checkDialog] = useState(false);
    const [isItem, showItem] = useState(false);

    useEffect(() => {
        if (!cavansRef.current.canvas) {
            let canvas = document.createElement("canvas");
            let width = 698, height = 1066;

            canvas.width = width;
            canvas.height = height;

            cavansRef.current.canvas = canvas;

            let image = imageRef.current as HTMLImageElement;
            let { offsetWidth, offsetHeight } = image.parentElement?.parentElement as HTMLDivElement;
            let sw = offsetWidth / width, sh = offsetHeight / height;

            let scale = sw > sh ? sh : sw;

            const isMale = getUserType();

            image.width = width * scale;
            image.height = height * scale;

            const items = loadItem();

            drawCover(canvas, {
                width, height, isMale, value: items.size, items
            }).then(() => {
                image.src = canvas.toDataURL("image/png");
            })
        }
    }, []);

    return <div className={styles.page}>
        {isDialog && <div className={styles.bg} onPointerDown={() => checkDialog(false)}>
            长按保存分享图片
        </div>}
        {isItem && <GetItems close={() => showItem(false)} />}
        <div className={styles.ubox}>
            <div className={styles.img}>
                <img ref={imageRef} />
            </div>
        </div>
        <div className={styles.itemInfo} onClick={() => showItem(true)}>
        </div>
        <div className={styles.btns}>
            <div className={styles.btn + " " + styles.share} onClick={() => prew()}>
            </div>
            <div className={styles.btn + " " + styles.addBtn} onClick={() => checkDialog(true)}>
            </div>
        </div>
    </div>
}